import boat from '../../assets/new-assets/home/brand-collabs/boat.webp';
import decathlon from '../../assets/new-assets/home/brand-collabs/decathlon.webp';
import Binge from '../../assets/new-assets/home/brand-collabs/Binge.webp';
import WiseLife from '../../assets/new-assets/home/brand-collabs/WiseLife.webp';
import TCR from '../../assets/new-assets/home/brand-collabs/TCR.webp';
import Noise from '../../assets/new-assets/home/brand-collabs/Noise.webp';
import Kapiva from '../../assets/new-assets/home/brand-collabs/Kapiva.webp';
import EatBetter from '../../assets/new-assets/home/brand-collabs/Eat_Better_cropped.webp';

export const data = [
  {
    id: 1,
    img: boat,
  },
  {
    id: 21,
    img: Binge,
  },
  {
    id: 22,
    img: WiseLife,
  },

  {
    id: 4,
    img: EatBetter,
  },

  {
    id: 73,
    img: Kapiva,
  },

  {
    id: 33,
    img: Noise,
  },

  {
    id: 53,
    img: decathlon,
  },

  {
    id: 23,
    img: TCR,
  },

  {
    id: 11,
    img: boat,
  },
  {
    id: 41,
    img: Binge,
  },
  {
    id: 62,
    img: WiseLife,
  },

  {
    id: 42,
    img: EatBetter,
  },
  {
    id: 7,
    img: Kapiva,
  },
  {
    id: 3,
    img: Noise,
  },
  {
    id: 5,
    img: decathlon,
  },
  {
    id: 2,
    img: TCR,
  },
];
