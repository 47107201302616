import React, { useRef, useEffect, useState, ReactNode } from 'react';

interface ConsistentHeightWrapperProps {
  children: ReactNode;
  className?: string;
}

const ConsistentHeightWrapper: React.FC<ConsistentHeightWrapperProps> = ({
  children,
  className = ``,
}) => {
  const [maxHeight, setMaxHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateMaxHeight = () => {
      const heights = Array.from(
        document.querySelectorAll(`.consistent-height-wrapper`),
      ).map((el) => el.scrollHeight);
      const newMaxHeight = Math.max(...heights);
      setMaxHeight(newMaxHeight);
    };

    updateMaxHeight();
    window.addEventListener(`resize`, updateMaxHeight);
    return () => window.removeEventListener(`resize`, updateMaxHeight);
  }, []);

  return (
    <div
      ref={ref}
      className={`consistent-height-wrapper ${className}`}
      style={{ minHeight: `${maxHeight}px` }}
    >
      {children}
    </div>
  );
};

export default ConsistentHeightWrapper;
