'use client';
import React from 'react';
import AudioComp from './AudioComp';
// import triangle from '../../assets/new-assets/home/features/meditationBg.webp';
import triangle from '../../assets/new-assets/home/features/meditationBg (1).webp';
// import phone1 from '../../assets/new-assets/home/features/purplephone1.webp';
// import phone2 from '../../assets/new-assets/home/features/purplephone2.webp';
// import ScallopMeditation from '../../assets/new-assets/home/features/scallopMeditation.svg';
import ScallopMeditation from '../../assets/new-assets/home/features/scallopMeditation.svg';
// import Bg from '../../assets/new-assets/home/features/bgMeditation.svg';
import { useMobileResponsive } from '@/hooks';
import { cn } from '@/utils';
import Image from 'next/image';
import ConsistentHeightWrapper from './ConsistentHeightWrapper';

const MediateComp = () => {
  const { windowWidth } = useMobileResponsive();

  return (
    <ConsistentHeightWrapper className="relative block md:flex justify-around items-center bg-[#9D77EE] w-full p-8 mt-32">
      <div
        className={cn(`absolute  left-0 -top-8  min-w-full`, {
          '-top-4': windowWidth < 560,
          '-top-14': windowWidth > 1120,
          '-top-20': windowWidth > 1800,
        })}
      >
        <ScallopMeditation className="" />
      </div>

      <div className="text-level-purple mt-10 ml-0 md:ml-10 md:w-[35%] ">
        <div className="space-y-3">
          <strong className="text-2xl md:text-5xl font-extrabold ">
            Meditations & Breathwork
          </strong>
          <p className="text-lg font-semibold">
            To Enhance Your Clarity,Focus, Memory and Creativity.
          </p>
        </div>

        <div className="pt-2  space-y-3">
          <span className="text-lg font-semibold text-[#0F0622]">
            Meditations for you
          </span>

          <AudioComp compName={`meditate`} />
        </div>
      </div>

      <div className="relative md:w-[35%]">
        <Image src={triangle} priority alt="Mediation on Level Supermind " />
        {/* <div className="absolute top-32 transition-all duration-300 hover:-translate-x-10">
          <img src={phone1.src} alt="" className="phone" />
        </div>
        <div className="absolute top-12 right-0 transition-all duration-300 hover:translate-x-10">
          <img src={phone2.src} alt="" className="phone" />
        </div> */}
      </div>
    </ConsistentHeightWrapper>
  );
};

export default MediateComp;

// 'use client';
// import React from 'react';
// import AudioComp from './AudioComp';
// // import triangle from '../../assets/new-assets/home/features/meditationBg.webp';
// import triangle from '../../assets/new-assets/home/features/meditationBg (1).webp';
// // import phone1 from '../../assets/new-assets/home/features/purplephone1.webp';
// // import phone2 from '../../assets/new-assets/home/features/purplephone2.webp';
// // import ScallopMeditation from '../../assets/new-assets/home/features/scallopMeditation.svg';
// import ScallopMeditation from '../../assets/new-assets/home/features/scallopMeditation.svg';
// // import Bg from '../../assets/new-assets/home/features/bgMeditation.svg';
// import { useMobileResponsive } from '@/hooks';
// import { cn } from '@/utils';
// import Image from 'next/image';
// import ConsistentHeightWrapper from './ConsistentHeightWrapper';

// const MediateComp = () => {
//   const { windowWidth } = useMobileResponsive();

//   return (
//     <ConsistentHeightWrapper className="relative block md:flex justify-around items-center bg-[#9D77EE] w-full px-8 py-0  mt-5 lg:mt-10 xl:mt-20">
//       <div
//         className={cn(`absolute  left-0 -top-8  min-w-full`, {
//           '-top-4': windowWidth < 560,
//           '-top-14': windowWidth > 1120,
//           '-top-20': windowWidth > 1800,
//         })}
//       >
//         <ScallopMeditation className="" />
//       </div>

//       <div className="text-level-purple mt-10 ml-0 md:ml-10 md:w-[35%] ">
//         <div className="space-y-3">
//           <strong className="text-2xl md:text-5xl font-extrabold ">
//             Meditations & Breathwork
//           </strong>
//           <p className="text-md md:text-xl font-semibold">
//             To Enhance Your Clarity,Focus, Memory and Creativity.
//           </p>
//         </div>

//         <div className="pt-8  space-y-3">
//           <span className="text-lg font-semibold text-[#0F0622]">
//             Meditations for you
//           </span>

//           <AudioComp compName={`meditate`} />
//         </div>
//       </div>

//       <div className="relative pt-10 md:w-[35%]">
//         <Image src={triangle} priority alt="Mediation on Level Supermind " />
//         {/* <div className="absolute top-32 transition-all duration-300 hover:-translate-x-10">
//           <img src={phone1.src} alt="" className="phone" />
//         </div>
//         <div className="absolute top-12 right-0 transition-all duration-300 hover:translate-x-10">
//           <img src={phone2.src} alt="" className="phone" />
//         </div> */}
//       </div>
//     </ConsistentHeightWrapper>
//   );
// };

// export default MediateComp;
