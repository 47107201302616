/* eslint-disable @next/next/no-img-element */
import {
  meditationReccomendation,
  musicReccomendation,
  sleepReccomendation,
  workoutReccomendation,
} from '@/config/free-meditations';
import { formatTimeMeditation, mediaSessionController } from '@/pages/meditate';
import { cn } from '@/utils';
import {
  faCircleNotch,
  faPause,
  faPlay,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalVideo from 'react-modal-video';
import Journal from '../../assets/new-assets/journal.webp';

import React, { useState } from 'react';
import Image from 'next/image';

export function AudioPlayer(props: {
  id: string;
  url: string;
  onPlay: () => boolean;
  className?: string;
  downloadNowRef: HTMLDivElement | null;
}) {
  const audioRef = React.useRef<HTMLAudioElement>(null);
  const [progress, setProgress] = React.useState(0);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isBuffering, setIsBuffering] = React.useState(false);

  React.useEffect(() => {
    const audio = audioRef.current;

    if (!audio) return;

    audio.addEventListener(`playing`, () => {
      setIsPlaying(() => true);
      setIsBuffering(() => false);
    });

    audio.addEventListener(`waiting`, () => {
      setIsPlaying(() => false);
      setIsBuffering(() => true);
    });

    audio.addEventListener(`pause`, () => {
      setIsPlaying(() => false);
      setIsBuffering(() => false);
    });

    audio.addEventListener(`play`, () => {
      setIsPlaying(() => true);
      setIsBuffering(() => false);
    });

    audio.addEventListener(`ended`, () => {
      setIsPlaying(() => false);
      setIsBuffering(() => false);
      audio.currentTime = 0;
    });

    const timer = setInterval(() => {
      setProgress(() => audio.currentTime / audio.duration);
    }, 200);

    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    const audio = audioRef.current;

    const endedListener = () => {
      props.downloadNowRef?.scrollIntoView({ behavior: `smooth` });
    };

    audio?.addEventListener(`ended`, endedListener);
    return () => audio?.removeEventListener(`ended`, endedListener);
  }, [props.downloadNowRef]);

  return (
    // <div className={[`text-center`].join(` `)}>
    <div
      onClick={() => {
        window.document.querySelectorAll(`audio`).forEach((el) => {
          el.pause();
        });

        if (typeof props.onPlay !== `function`)
          throw Error(`onPlay must be a function`);

        if (!props.onPlay()) return;

        isPlaying ? audioRef.current?.pause() : audioRef.current?.play();
      }}
      className={[`text-center  sm:flex sm:items-center cursor-pointer`].join(
        ` `,
      )}
    >
      <div
        className={[
          `m-1 sm:m-3`,
          `bg-level-purple `,
          `h-[2rem] w-[2rem] sm:h-[2.7rem] sm:w-[2.7rem]`,
          `flex items-center justify-center`,
          `rounded-full`,
          props.className || ``,
        ].join(` `)}
      >
        <audio ref={audioRef} src={props.url} className="hidden" />

        <div className="flex flex-col">
          <button id={props.id} className={[`flex`, `flex-row`].join(` `)}>
            <FontAwesomeIcon
              icon={isBuffering ? faCircleNotch : isPlaying ? faPause : faPlay}
              width="1em"
              height="1em"
              color="white"
              fontSize="1rem"
              className={[`cursor-pointer`].join(` `)}
            />
          </button>
        </div>
      </div>
      <small
        className="text-white  text-center"
        style={{ opacity: progress <= 0 ? 0 : 100 }}
      >
        {formatTimeMeditation(audioRef.current?.currentTime || 0)}
      </small>
    </div>
  );
}

const AudioComp = (props: { compName: string }) => {
  const downloadNowRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);

  const [clickedBtnId, setClickedBtnId] = React.useState(``);
  // const [hasUserSubmittedForm, setHasUserSubmittedForm] = React.useState(false);
  const [hasUserSubmittedForm, setHasUserSubmittedForm] = React.useState(true);

  return (
    <>
      <div className={[`max-w-7xl`, `m-auto`, `gap-8`].join(` `)}>
        {props.compName === `meditate` && (
          <>
            {meditationReccomendation.map((meditation, i) => (
              <div
                key={meditation.id}
                className={[
                  `overflow-hidden`,
                  `flex h-fit `,
                  `justify-between`,
                  `rounded-xl`,
                  `grow my-2 bg-black/30 `,
                ].join(` `)}
              >
                <div
                  className={cn(`bg-transparent  p-3`, {
                    'bg-[#44199A]': props.compName === `meditate`,
                    'bg-[#35646F]': props.compName === `music`,
                    'bg-[#07039B]': props.compName === `sleep`,
                    'bg-[#CC9E06]': props.compName === `workout`,
                  })}
                >
                  <img
                    className="w-28   rounded-lg"
                    src={meditation.thumbnail_sm_url}
                    alt="meditation thumbnail"
                  />

                  {/* <Image
                    src={meditation.thumbnail_sm_url}
                    alt="meditation thumbnail"
                    width={112}
                    height={112}
                    layout="intrinsic"
                  /> */}
                </div>

                <div className="w-full flex justify-between p-4">
                  <div className="text-sm text-white font-bold  ">
                    <div className="flex items-center space-x-2">
                      <div className="w-7 h-7 rounded-full bg-white">
                        <img
                          className="rounded-full"
                          src={meditation.coach_thumbnail}
                          alt="meditation coach"
                        />
                        {/* <Image
                          className="rounded-full"
                          src={meditation.coach_thumbnail}
                          alt="meditation coach"
                          width={100}
                          height={100}
                          layout="intrinsic"
                        /> */}
                      </div>
                      <div className="line-clamp-1">
                        {meditation.coach_name}
                      </div>
                    </div>
                    <h2 className="text-sm md:text-lg text-white font-bold line-clamp-1">
                      {meditation.name}
                    </h2>
                    <h2 className="text-white font-light ">
                      {meditation.time}
                    </h2>
                  </div>
                  <AudioPlayer
                    id={`play-btn-${i}`}
                    url={meditation.activity_url}
                    downloadNowRef={downloadNowRef.current}
                    onPlay={() => {
                      setClickedBtnId(`play-btn-${i}`);
                      mediaSessionController(
                        meditation.name,
                        meditation.coach_name,
                        `Level Meditations`,
                        {
                          src: meditation.background.src,
                          sizes: `512x512`,
                          type: `image/webp`,
                        },
                      );
                      return hasUserSubmittedForm;
                    }}
                  />
                </div>
              </div>
            ))}
          </>
        )}

        {props.compName === `music` && (
          <>
            {musicReccomendation.map((meditation, i) => (
              <div
                key={meditation.id}
                className={[
                  `overflow-hidden`,
                  `flex h-fit `,
                  `justify-between`,
                  `rounded-xl`,
                  `grow my-2 bg-black/30 `,
                ].join(` `)}
              >
                <div
                  className={cn(`bg-transparent p-3`, {
                    'bg-[#44199A]': props.compName === `meditate`,
                    'bg-[#35646F]': props.compName === `music`,
                    'bg-[#07039B]': props.compName === `sleep`,
                    'bg-[#CC9E06]': props.compName === `workout`,
                  })}
                >
                  <img
                    className="w-28 rounded-lg"
                    src={meditation.thumbnail_sm_url}
                    alt="meditation thumbnail"
                  />
                </div>

                <div className="w-full flex justify-between p-4">
                  <div className="text-sm text-white font-bold  ">
                    <div className="flex items-center space-x-2">
                      <div className="w-7 h-7 rounded-full bg-white">
                        <img
                          className="rounded-full"
                          src={meditation.coach_thumbnail}
                          alt="meditation coach"
                        />
                      </div>
                      <div className="line-clamp-1">
                        {meditation.coach_name}
                      </div>
                    </div>
                    <h2 className="text-sm md:text-lg text-white font-bold line-clamp-1">
                      {meditation.name}
                    </h2>
                    <h2 className="text-white font-light ">
                      {meditation.time}
                    </h2>
                  </div>
                  <AudioPlayer
                    id={`play-btn-${i}`}
                    url={meditation.activity_url}
                    downloadNowRef={downloadNowRef.current}
                    onPlay={() => {
                      setClickedBtnId(`play-btn-${i}`);
                      mediaSessionController(
                        meditation.name,
                        meditation.coach_name,
                        `Level Meditations`,
                        {
                          src: meditation.background.src,
                          sizes: `512x512`,
                          type: `image/webp`,
                        },
                      );
                      return hasUserSubmittedForm;
                    }}
                  />
                </div>
              </div>
            ))}
          </>
        )}

        {props.compName === `sleep` && (
          <>
            {sleepReccomendation.map((meditation, i) => (
              <div
                key={meditation.id}
                className={[
                  `overflow-hidden`,
                  `flex h-fit `,
                  `justify-between`,
                  `rounded-xl`,
                  `grow my-2 bg-black/30 `,
                ].join(` `)}
              >
                <div
                  className={cn(`bg-transparent p-3`, {
                    'bg-[#44199A]': props.compName === `meditate`,
                    'bg-[#35646F]': props.compName === `music`,
                    'bg-[#07039B]': props.compName === `sleep`,
                    'bg-[#CC9E06]': props.compName === `workout`,
                  })}
                >
                  <img
                    className="w-28 rounded-lg"
                    src={meditation.thumbnail_sm_url}
                    alt="meditation thumbnail"
                  />
                </div>

                <div className="w-full flex justify-between p-4">
                  <div className="text-sm text-white font-bold  ">
                    <div className="flex items-center space-x-2">
                      <div className="w-7 h-7 rounded-full bg-white">
                        <img
                          className="rounded-full"
                          src={meditation.coach_thumbnail}
                          alt="meditation coach"
                        />
                      </div>
                      <div className="line-clamp-1">
                        {meditation.coach_name}
                      </div>
                    </div>
                    <h2 className="text-base md:text-lg text-white font-bold line-clamp-1">
                      {meditation.name}
                    </h2>
                    <h2 className="text-white font-light ">
                      {meditation.time}
                    </h2>
                  </div>
                  <AudioPlayer
                    id={`play-btn-${i}`}
                    url={meditation.activity_url}
                    downloadNowRef={downloadNowRef.current}
                    onPlay={() => {
                      setClickedBtnId(`play-btn-${i}`);
                      mediaSessionController(
                        meditation.name,
                        meditation.coach_name,
                        `Level Meditations`,
                        {
                          src: meditation.background.src,
                          sizes: `512x512`,
                          type: `image/webp`,
                        },
                      );
                      return hasUserSubmittedForm;
                    }}
                  />
                </div>
              </div>
            ))}
          </>
        )}

        {props.compName === `journal` && (
          <>
            <div
              className={[
                `overflow-hidden`,
                `flex h-fit `,
                `justify-between`,
                `grow my-2 w-full md:w-3/4`,
              ].join(` `)}
            >
              <a href="https://mind.level.game/share/etdU">
                <Image src={Journal} />
              </a>
            </div>
          </>
        )}

        {props.compName === `workout` && (
          <>
            {workoutReccomendation.map((meditation, i) => (
              <>
                <div
                  key={meditation.id}
                  className={[
                    `overflow-hidden`,
                    `flex h-fit `,
                    `justify-between`,
                    `rounded-xl`,
                    `grow my-2 bg-black/30 `,
                  ].join(` `)}
                >
                  <div
                    className={cn(`bg-transparent p-1`, {
                      'bg-[#44199A]': props.compName === `meditate`,
                      'bg-[#35646F]': props.compName === `music`,
                      'bg-[#07039B]': props.compName === `sleep`,
                      'bg-[#ffc607]': props.compName === `workout`,
                    })}
                  >
                    <img
                      className="w-36 rounded-lg"
                      src={meditation.thumbnail_sm_url}
                      alt="meditation thumbnail"
                    />
                  </div>

                  <div className="w-full flex justify-between p-4">
                    <div className="text-sm text-white font-bold  ">
                      <div className="flex items-center space-x-2">
                        <div className="w-7 h-7 rounded-full bg-white">
                          <img
                            className="rounded-full"
                            src={meditation.coach_thumbnail}
                            alt="meditation coach"
                          />
                        </div>
                        <div className="line-clamp-1">
                          {meditation.coach_name}
                        </div>
                      </div>
                      <h2 className="text-sm md:text-lg text-white font-bold line-clamp-1">
                        {meditation.name}
                      </h2>
                      <h2 className="text-white font-light ">
                        {meditation.time}
                      </h2>
                    </div>

                    <div
                      className={[
                        `m-2`,
                        `bg-level-purple `,
                        `h-[2.5rem] w-[2.5rem]`,
                        `flex items-center justify-center`,
                        `rounded-full`,
                      ].join(` `)}
                    >
                      <div className="flex flex-col">
                        <button
                          onClick={() => {
                            setVideoUrl(meditation.activity_url);
                            setOpen(true);
                          }}
                          className={[`flex`, `flex-row`].join(` `)}
                        >
                          <FontAwesomeIcon
                            icon={faPlay}
                            width="1em"
                            height="1em"
                            color="white"
                            fontSize="1rem"
                            className={[`cursor-pointer`].join(` `)}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </>
        )}
        {videoUrl && (
          <ModalVideo
            channel="custom"
            isOpen={isOpen}
            url={videoUrl}
            onClose={() => setOpen(false)}
            allowFullScreen={true}
          />
        )}
      </div>
    </>
  );
};

export default AudioComp;
