import React from 'react';
import AudioComp from './AudioComp';
// import triangle from '../../assets/new-assets/home/features/blueTri.webp';
// import triangle from '../../assets/new-assets/home/features/blueBg.webp';
import triangle from '../../assets/new-assets/home/features/blueBg (1).webp';
// import phone1 from '../../assets/new-assets/home/features/bluePhone1.webp';
// import phone2 from '../../assets/new-assets/home/features/bluePhone2.webp';
import Scallop from '../../assets/new-assets/home/features/sleepScallop.svg';

import { cn } from '@/utils';
import { useMobileResponsive } from '@/hooks';
import Image from 'next/image';
import ConsistentHeightWrapper from './ConsistentHeightWrapper';

const SleepComp = () => {
  const { windowWidth } = useMobileResponsive();

  return (
    <ConsistentHeightWrapper className="relative block md:flex justify-around items-center bg-[#6A6CFA] text-[#07039B] w-full p-8 mt-32">
      <div
        className={cn(`absolute  left-0 -top-8  min-w-full`, {
          '-top-4': windowWidth < 560,
          '-top-14': windowWidth > 1120,
          '-top-20': windowWidth > 1800,
        })}
      >
        <Scallop className="" />
      </div>
      <div className="text-level-purple mt-10  ml-0 md:ml-10 md:w-[35%]">
        <div className="space-y-3">
          <strong className="text-2xl md:text-5xl font-extrabold ">
            Relaxing Sleep Stories
          </strong>
          <p className="text-xl font-semibold">
            Have a deep and relaxing sleep with stories of ancient wisdom,
            history & more.
          </p>
        </div>

        <div className="pt-8  space-y-3">
          <span className="text-lg font-semibold text-[#0F0622]">
            Sleep stories for you
          </span>

          <AudioComp compName="sleep" />
        </div>
      </div>
      <div className="relative pt-10 md:w-[35%]">
        <Image src={triangle} priority />
        {/* <div className="absolute top-32 transition-all duration-300 hover:-translate-x-10">
          <img src={phone1.src} alt="" className="phone" />
        </div>
        <div className="absolute top-12 right-0 transition-all duration-300 hover:translate-x-10">
          <img src={phone2.src} alt="" className="phone" />
        </div> */}
      </div>
    </ConsistentHeightWrapper>
  );
};

export default SleepComp;

// import React from 'react';
// import AudioComp from './AudioComp';
// import triangle from '../../assets/new-assets/home/features/blueBg (1).webp';
// import Scallop from '../../assets/new-assets/home/features/sleepScallop.svg';

// import { cn } from '@/utils';
// import { useMobileResponsive } from '@/hooks';
// import Image from 'next/image';
// import ConsistentHeightWrapper from './ConsistentHeightWrapper';

// const SleepComp = () => {
//   const { windowWidth } = useMobileResponsive();

//   return (
//     <ConsistentHeightWrapper className="relative block md:flex justify-around items-center bg-[#6A6CFA] w-full px-8 py-0 mt-0 lg:mt-10 xl:mt-20">
//       <div
//         className={cn(`absolute  left-0 -top-8  min-w-full`, {
//           '-top-4': windowWidth < 560,
//           '-top-14': windowWidth > 1120,
//           '-top-20': windowWidth > 1800,
//         })}
//       >
//         <Scallop className="" />
//       </div>
//       <div className="text-level-purple mt-10  ml-0 md:ml-10 md:w-[35%]">
//         <div className="space-y-3">
//           <strong className="text-3xl md:text-5xl font-extrabold ">
//             Relaxing Sleep Stories
//           </strong>
//           <p className="text-xl font-semibold">
//             Have a deep and relaxing sleep with stories of ancient wisdom,
//             history & more.
//           </p>
//         </div>

//         <div className="pt-8  space-y-3">
//           <span className="text-lg font-semibold text-[#0F0622]">
//             Sleep stories for you
//           </span>

//           <AudioComp compName="sleep" />
//         </div>
//       </div>
//       <div className="relative pt-10 md:w-[35%]">
//         <Image src={triangle} priority />
//         {/* <div className="absolute top-32 transition-all duration-300 hover:-translate-x-10">
//           <img src={phone1.src} alt="" className="phone" />
//         </div>
//         <div className="absolute top-12 right-0 transition-all duration-300 hover:translate-x-10">
//           <img src={phone2.src} alt="" className="phone" />
//         </div> */}
//       </div>
//     </ConsistentHeightWrapper>
//   );
// };

// export default SleepComp;
