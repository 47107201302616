import React from 'react';
//import { recaptchaKeys } from '@/config/recaptcha';
//import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { cn } from '@/utils';
//import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

function ContactFormNoRecaptcha() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [apiResp, setApiResp] = React.useState<{ [key: string]: any }>({});
  //const [recaptchaState, setRecaptchaState] = React.useState<
  //'LOADING' | 'INVALID' | 'VALIDe
  //>(`LOADING`);

  return (
    <div id="contact" className="px-4 py-16  bg-[#223548]">
      <div
        className={[`m-auto`, `text-center`, `text-white`, `max-w-3xl`].join(
          ` `,
        )}
      >
        <h2
          className={[`px-2`, `font-black`, `md:text-5xl`, `text-3xl`].join(
            ` `,
          )}
        >
          Get In Touch
        </h2>

        <p className="text-md md:text-xl mt-4">
          Contact Us To Talk About Meditation, Collaboration, Queries (or Even
          Just For a Chat)
        </p>
      </div>

      {apiResp.status === `ERR` && (
        <div
          className={[
            `p-4`,
            `border`,
            `border-red-500`,
            `bg-red-100`,
            `text-red-500`,
            `font-bold`,
            `rounded-lg`,
            `mt-10`,
            `max-w-4xl`,
            `mx-auto`,
          ].join(` `)}
        >
          {apiResp.message}
        </div>
      )}

      {apiResp.status === `SUCCESS` && (
        <div
          className={[
            `p-4`,
            `border`,
            `border-green-500`,
            `bg-green-200`,
            `text-green-500`,
            `font-bold`,
            `rounded-lg`,
            `mt-10`,
            `max-w-4xl`,
            `mx-auto`,
          ].join(` `)}
        >
          {apiResp.message}
        </div>
      )}

      <form
        className={[
          `m-auto`,
          `max-w-4xl`,
          `flex`,
          `flex-col`,
          `gap-8`,
          `mt-6`,
          `mb-10`,
        ].join(` `)}
        onSubmit={async (ev) => {
          setIsLoading(true);
          ev.preventDefault();

          const form = ev.currentTarget;
          const formData = new FormData(form);
          const name = formData.get(`name`);
          const email = formData.get(`email`);
          const message = formData.get(`message`);

          if (!message || !email) {
            setIsLoading(false);
            setApiResp({
              status: `ERR`,
              message: `'Email' and 'Message' fields are required!`,
            });

            return;
          }

          //if (recaptchaState !== `VALID`) {
          //setIsLoading(false);
          //setApiResp({
          //status: `ERR`,
          //message: `Failed to verify reCaptcha. Try again later!`,
          //});

          //return;
          //}

          try {
            const raw = await fetch(`/api/deliver-message`, {
              method: `POST`,
              body: JSON.stringify({ name, email, message }),
            });

            if (!raw.ok) {
              setApiResp({
                status: `ERR`,
                message: `Something went wrong when sending email!`,
              });
            } else {
              form?.reset();
              setApiResp({
                status: `SUCCESS`,
                message: `Success! Message Sent!`,
              });
            }
          } catch (err) {
            setApiResp({
              status: `ERR`,
              message: `Something went wrong when sending email!`,
            });
          } finally {
            setIsLoading(false);
          }
        }}
      >
        <div
          className={[
            `flex`,
            `flex-col`,
            `md:flex-row`,
            `gap-8`,
            `md:gap-12`,
          ].join(` `)}
        >
          <input
            style={{ boxShadow: `inset 18px 7px 46px #00000029` }}
            placeholder="Your name"
            className="grow px-6 py-4 rounded-xl bg-black text-white placeholder:text-white border-2 border-[#373737] focus:border-level-purple"
            type="text"
            name="name"
          />
          <input
            style={{ boxShadow: `inset 18px 7px 46px #00000029` }}
            type="email"
            name="email"
            required
            placeholder="Your e-mail"
            className="grow px-6 py-4 rounded-xl bg-black text-white placeholder:text-white border-2 border-[#373737] focus:border-level-purple
            "
          />
        </div>

        <textarea
          name="message"
          style={{ boxShadow: `inset 18px 7px 46px #00000029` }}
          required
          placeholder="Type in your message"
          className={[
            `py-4`,
            `px-6`,
            `h-[10rem]`,
            `resize-none`,
            `rounded-xl`,
            `bg-black`,
            `text-white`,
            `placeholder:text-white`,
            `border-2`,
            `border-[#373737]`,
            `focus:border-level-purple`,
          ].join(` `)}
        ></textarea>

        <button
          disabled={isLoading}
          className={cn(
            `self-center bg-gradient-to-r from-[#FFC607] via-[#ffeca9]  to-[#F7D766] text-[#0F0622] text-lg  font-extrabold 
               hover:from-[#F7D766] hover:via-[#ffeca9]  hover:to-[#FFC607] 
              transition-all duration-500
              p-3 hover:p-4 px-5 hover:px-6 my-3 
              rounded-3xl w-60 h-14 max-w-xl
              md:text-xl md:my-4 transform hover:scale-105
            `,
          )}
        >
          {isLoading ? (
            <FontAwesomeIcon className="fa-spin" icon={faCircleNotch} />
          ) : (
            `Submit`
          )}
        </button>
      </form>
    </div>
  );
}

export const ContactForm = () => <ContactFormNoRecaptcha />;

{
  /*<GoogleReCaptchaProvider
    reCaptchaKey={recaptchaKeys.public}
    scriptProps={{ async: true, defer: true }}
  >
    <ContactFormNoRecaptcha />
    </GoogleReCaptchaProvider>*/
}
