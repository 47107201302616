import React from 'react';

type Props = {
  className?: string;
};

export function CircleShapeBox(props: Props) {
  return (
    <div className={[props.className].join(` `)}>
      <div className={[`relative`, `h-full`].join(` `)}>
        <div
          style={{
            background: `linear-gradient(#f2efff, #f2efff, #dad4f2, #8e7adc85)`,
          }}
          className={[
            `absolute`,
            `top-0`,
            `h-3/5`,
            `bg-red-500`,
            `w-full`,
            `z-10`,
          ].join(` `)}
        />

        <div
          style={{
            background: `linear-gradient(350.8deg, #f2efff, #f2efff, #f2efff, #dad4f2, #8e7adc85, #6758d2)`,
          }}
          className={[
            `absolute`,
            `top-[60%]`,
            `h-2/5`,
            `bg-red-500`,
            `w-full`,
            `z-10`,
          ].join(` `)}
        />
      </div>
    </div>
  );
}
