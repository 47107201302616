import React from 'react';
import data from './data.json';
import { StarRating } from '@/components/star-rating';
import ProductHuntImg from '../../assets/producthunt-finalist.png';
import AppScaleIMG from '../../assets/appscale_btn.webp';
import productHunt from '../../assets/productHunt.webp';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { cn } from '@/utils';

const PH_URL = `https://www.producthunt.com/posts/level-8a2041c3-cf57-4ca2-9a82-7639c86f3386`;

function useDevicePlatformType() {
  const [type, setType] = React.useState<'APPLE' | 'ANDROID'>(`ANDROID`);

  React.useEffect(() => {
    fetch(`/api/platform`)
      .then((data: any) => setType(() => data.platform))
      .catch(() => setType(`ANDROID`));
  }, []);

  return type;
}

export function Testimonials() {
  const deviceType = useDevicePlatformType();

  return (
    <div className="bg-level-purple text-white">
      <div className={cn(`pt-20 pb-2 `)}>
        <a
          href={
            deviceType === `APPLE`
              ? `https://apps.apple.com/in/app/level-mind-sleep-journal-body/id1623828602?see-all=reviews`
              : `https://play.google.com/store/apps/details?id=level.game`
          }
          className={[
            `px-2`,
            `-mb-7 md:-mb-0`,
            `text-3xl`,
            `md:text-5xl`,
            `font-black`,
            `text-center`,
            `block`,
          ].join(` `)}
          target="_blank"
          rel="noreferrer"
        >
          Reviews From Our App Users
        </a>
      </div>

      <div className={[`relative`].join(` `)}>
        <div className={[`pt-14 md:pt-10 pb-24`].join(` `)}>
          <Splide
            options={{
              type: `loop`,
              drag: true,
              perMove: 1,
              ////perPage: innerWidth > 900 ? 3 : 1,
              pagination: false,
              autoplay: true,
              flickMaxPages: 2,

              arrows: false,
              interval: 2000,
              easing: `cubic-bezier(0, 0.55, 0.45, 1)`,
              focus: `center`,
              pauseOnHover: true,
            }}
          >
            {data.map((feedback, i) => (
              <SplideSlide key={i} className="max-w-[40rem] flex items-stretch">
                <div
                  className="mx-6 md:mx-10 px-8 md:px-10  flex justify-center md:justify-start md:items-center flex-col md:flex-row gap-x-10 rounded-3xl border-2 border-[#373737] bg-[#362E48]
     w-full"
                  style={
                    {
                      // boxShadow: `inset 0px 8px 30px #7356E83B`,
                    }
                  }
                >
                  <span
                    className={[
                      `rounded-full`,
                      `flex`,
                      `items-center`,
                      `justify-center`,
                      `min-w-[7rem] pt-3`,
                    ].join(` `)}
                  >
                    {/* eslint-disable-next-line */}
                    <img
                      src={feedback.photo}
                      width={100}
                      height={100}
                      alt="feedback image"
                      className="rounded-3xl border-2 border-[#373737] grow max-w-[5.5rem] md:max-w-[7rem]"
                      onError={(ev) =>
                        (ev.currentTarget.src = `https://picsum.photos/500`)
                      }
                    />
                  </span>

                  <div className="text-md md:text-xl text-center md:text-left flex md:block flex-col my-4 ">
                    <p className="mb-1">
                      <StarRating
                        totalRatingCount={5}
                        rating={feedback.rating}
                      />
                    </p>
                    <strong className="pl-1 -order-1">{feedback.name}</strong>
                    <p className="pl-1">{feedback.content}</p>
                  </div>
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div>
    </div>
  );
}

{
  data.map((feedback, i) => (
    <SplideSlide key={i} className="max-w-[40rem] flex items-stretch">
      <div
        className="mx-4 md:mx-10 px-8 md:px-10  flex justify-center md:justify-start md:items-center flex-col md:flex-row gap-x-10 rounded-3xl border-2 border-[#373737] bg-[#362E48]
     w-full"
        style={
          {
            // boxShadow: `inset 0px 8px 30px #7356E83B`,
          }
        }
      >
        <span
          className={[
            `rounded-full`,
            `flex`,
            `items-center`,
            `justify-center`,
            `min-w-[7rem]`,
          ].join(` `)}
        >
          {/* eslint-disable-next-line */}
          <img
            src={feedback.photo}
            width={100}
            height={100}
            alt="feedback image"
            className="rounded-3xl border-2 border-[#373737] grow max-w-[5.5rem] md:max-w-[7rem]"
            onError={(ev) =>
              (ev.currentTarget.src = `https://picsum.photos/500`)
            }
          />
        </span>

        <div className="text-md md:text-xl text-center md:text-left flex md:block flex-col my-4 ">
          <p className="mb-1">
            <StarRating totalRatingCount={5} rating={feedback.rating} />
          </p>
          <strong className="pl-1 -order-1">{feedback.name}</strong>
          <p className="pl-1">{feedback.content}</p>
        </div>
      </div>
    </SplideSlide>
  ));
}
