import React, { useReducer } from 'react';
import Image from 'next/image';
import { featuresList } from './features';
import { useMobileResponsive } from '@/hooks';
import { cn } from '@/utils';
import MediateComp from './MediateComp';
import FocusComp from './MusicComp';
import MusicComp from './MusicComp';
import WorkoutComp from './WorkoutComp';
import SleepComp from './SleepComp';
import JournalComp from './JournalComp';

export function KeyFeatures() {
  const reducer = (state: any, action: { type: any }) => {
    switch (action.type) {
      case `MEDITATE`:
        return { comp: `Meditate` };
      case `FOCUS_MUSIC`:
        return { comp: `Focus Music` };
      case `WORKOUTS`:
        return { comp: `Workouts` };
      case `SLEEP`:
        return { comp: `Sleep` };
      case `JOURNAL`:
        return { comp: `Journal` };
      default:
        return state;
    }
  };

  const initialState = { comp: `Meditate` };
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isMobile } = useMobileResponsive();

  return (
    <div className="bg-[#0f0622] text-white ">
      <h2
        className={[
          `px-2 mt-20 md:mt-40`,
          `font-black`,
          `text-2xl md:text-5xl`,
          `text-white`,
          `text-center pb-3`,
          ``,
        ].join(` `)}
      >
        How to get a SuperMind?
      </h2>

      <div className="flex flex-wrap justify-center items-center space-x-3 space-y-3 px-4 py-3 ">
        <div></div>
        <button
          onClick={() => dispatch({ type: `MEDITATE` })}
          className={cn(
            `w-40  font-bold border border-[#9D77EE] py-2 rounded-full bg-transparent hover:bg-gradient-to-r hover:to-[#44199A] hover:from-[#9D77EE]`,
            {
              'bg-gradient-to-r to-[#44199A] from-[#9D77EE]':
                state.comp === `Meditate`,
            },
          )}
        >
          Meditate
        </button>
        <button
          onClick={() => dispatch({ type: `FOCUS_MUSIC` })}
          className={cn(
            `w-40  font-bold border border-[#8FCECD] py-2 rounded-full bg-transparent hover:bg-gradient-to-r hover:to-[#35646F] hover:from-[#8FCECD]`,
            {
              'bg-gradient-to-r to-[#35646F] from-[#8FCECD]':
                state.comp === `Focus Music`,
            },
          )}
        >
          Music
        </button>
        <button
          onClick={() => dispatch({ type: `WORKOUTS` })}
          className={cn(
            `w-40  font-bold border border-[#F7D766] py-2 rounded-full bg-transparent hover:bg-gradient-to-r hover:to-[#BA9012] hover:from-[#F7D766]`,
            {
              'bg-gradient-to-r to-[#BA9012] from-[#F7D766]':
                state.comp === `Workouts`,
            },
          )}
        >
          Workouts
        </button>
        <button
          onClick={() => dispatch({ type: `SLEEP` })}
          className={cn(
            `w-40  font-bold border border-[#6A6CFA] py-2 rounded-full bg-transparent hover:bg-gradient-to-r hover:to-[#07039B] hover:from-[#6A6CFA]`,
            {
              'bg-gradient-to-r to-[#07039B] from-[#6A6CFA]':
                state.comp === `Sleep`,
            },
          )}
        >
          Sleep
        </button>
        <button
          onClick={() => dispatch({ type: `JOURNAL` })}
          className={cn(
            `w-40  font-bold border border-[#F068AA] py-2 rounded-full bg-transparent hover:bg-gradient-to-r hover:to-[#970A55] hover:from-[#F068AA]`,
            {
              'bg-gradient-to-r to-[#970A55] from-[#F068AA]':
                state.comp === `Journal`,
            },
          )}
        >
          Journal
        </button>
      </div>

      <div className="flex justify-center items-center  space-x-3 -mt-24 md:-mt-0">
        {state.comp === `Meditate` && <MediateComp />}
        {state.comp === `Focus Music` && <MusicComp />}
        {state.comp === `Workouts` && <WorkoutComp />}
        {state.comp === `Sleep` && <SleepComp />}
        {state.comp === `Journal` && <JournalComp />}
      </div>
    </div>
  );
}

// import React, { useRef, useEffect, useState } from 'react';
// import { cn } from '@/utils';
// import MediateComp from './MediateComp';
// import MusicComp from './MusicComp';
// import WorkoutComp from './WorkoutComp';
// import SleepComp from './SleepComp';
// import JournalComp from './JournalComp';
// import { useMobileResponsive } from '@/hooks';

// interface Feature {
//   name: string;
//   Component: React.ComponentType;
//   color: string;
// }

// const features: Feature[] = [
//   {
//     name: `Meditate`,
//     Component: MediateComp,
//     color: `from-[#9D77EE] to-[#44199A]`,
//   },
//   { name: `Music`, Component: MusicComp, color: `from-[#8FCECD] to-[#35646F]` },
//   {
//     name: `Workouts`,
//     Component: WorkoutComp,
//     color: `from-[#F7D766] to-[#BA9012]`,
//   },
//   {
//     name: `Journal`,
//     Component: JournalComp,
//     color: `from-[#F068AA] to-[#970A55]`,
//   },
//   { name: `Sleep`, Component: SleepComp, color: `from-[#6A6CFA] to-[#07039B]` },
// ];

// export const KeyFeatures: React.FC = () => {
//   const { isMobile, windowWidth } = useMobileResponsive();
//   const [activeFeature, setActiveFeature] = useState<number>(0);
//   const featureRefs = useRef<(HTMLDivElement | null)[]>(
//     features.map(() => null),
//   );

//   useEffect(() => {
//     const handleScroll = () => {
//       const scrollPosition = window.scrollY;
//       featureRefs.current.forEach((ref, index) => {
//         if (ref) {
//           const { top } = ref.getBoundingClientRect();
//           if (top <= 0 && top > -ref.offsetHeight) {
//             setActiveFeature(index);
//           }
//         }
//       });
//     };

//     window.addEventListener(`scroll`, handleScroll);
//     return () => window.removeEventListener(`scroll`, handleScroll);
//   }, []);

//   return (
//     <div className="bg-[#0f0622] text-white min-h-screen">
//       <h2 className="px-2 pt-20 font-black text-2xl md:text-5xl text-white text-center pb-3">
//         How to get a SuperMind?
//       </h2>

//       <div className="px-2 flex flex-wrap justify-center items-center space-x-3 space-y-3 py-3 bg-[#0f0622]">
//         <div></div>
//         {features.map((feature, index) => (
//           <button
//             key={feature.name}
//             onClick={() => {
//               featureRefs.current[index]?.scrollIntoView({
//                 behavior: `smooth`,
//               });
//             }}
//             className={cn(
//               `w-32 md:w-40 font-bold border py-2 rounded-full bg-transparent hover:bg-gradient-to-r`,
//               `hover:${feature.color}`,
//               {
//                 [`bg-gradient-to-r ${feature.color}`]: activeFeature === index,
//               },
//             )}
//           >
//             {feature.name}
//           </button>
//         ))}
//       </div>

//       <div className="relative">
//         {features.map(({ name, Component, color }, index) => (
//           <div
//             key={name}
//             ref={(el) => (featureRefs.current[index] = el)}
//             className={cn(
//               `min-h-screen flex items-center justify-center sticky top-[-15em] md:top-0`,

//               //   {
//               //   'sticky top-0': true,
//               // }
//             )}
//           >
//             {/* <div className={cn(`w-full h-full bg-gradient-to-r`, color)}>

//             </div> */}
//             <Component />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };
