/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { cn } from '@/utils';
// import Image from 'next/image';
import React, { useCallback, useEffect, useState } from 'react';
// import circle from '../../assets/new-assets/home/about/circle1.webp';
import circle2 from '../../assets/new-assets/home/about/circle2.webp';
// import circle from '../../assets/new-assets/home/circlephone.webp';
// import circle2 from '../../assets/new-assets/home/hero/circle2.webp';
import landing2 from '../../assets/new-assets/home/hero/landing-2.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

export const ArrowIcon = () => {
  return (
    // <div className="w-7 h-7 bg-[#F9CE47] text-[#0f0622] pt-[3px] pl-0 md:p-1 md:pl-2 rounded-full">
    <div className="w-8 h-8 p-1 bg-[#F9CE47] text-[#0f0622] rounded-full flex items-center justify-center">
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
};

const AboutComp = () => {
  const [showSecondImage, setShowSecondImage] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  function debounce(
    func: { (value: any): void; apply?: any },
    delay: number | undefined,
  ) {
    let timeoutId: NodeJS.Timeout;
    return (...args: any) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        // eslint-disable-next-line prefer-spread
        func.apply(null, args);
      }, delay);
    };
  }

  const debouncedSetShowSecondImage = useCallback(
    debounce((value) => setShowSecondImage(value), 100),
    [],
  );

  useEffect(() => {
    debouncedSetShowSecondImage(isHovering);
  }, [isHovering, debouncedSetShowSecondImage]);

  // Solved Use 1 image and make it
  return (
    <>
      <div
        className=" bg-[#0f0622] text-white mx-auto block lg:flex justify-center items-center p-4 md:p-28 lg:p-30 mt-20 "
        style={{
          backgroundImage: `url(${landing2.src})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          zIndex: 10, // Ensuring this container has a higher z-index
        }}
      >
        <div
          className=" flex-1 
         flex justify-center items-center"
        >
          <figure className={cn(``, {})}>
            <div className="">
              <img
                src={circle2.src}
                className="w-[500px]"
                alt="Level SuperMind Best App 2023"
              />
            </div>
          </figure>
        </div>

        <div className="flex-1 text-center md:text-left p-4 pt-10 lg:pt-20 mb-8">
          <div className="space-y-4">
            <h2 className="text-2xl lg:text-5xl font-extrabold leading-snug pb-4 lg:pb-7">
              What is Level SuperMind?
            </h2>
            <span className="">
              An app to help you have a clear mind & better performance with
              activities curated by the experts.
            </span>
            <div className=" flex space-x-2 items-center pt-7">
              <div>
                <ArrowIcon />
              </div>
              <div className="text-left">
                Reduces stress, anxiety & overthinking
              </div>
            </div>
            <div className="flex space-x-2 items-center">
              <div>
                <ArrowIcon />
              </div>

              <div className="text-left">Improves sleep quality</div>
            </div>
            <div className="flex space-x-2 items-center pb-10">
              <div>
                <ArrowIcon />
              </div>

              <div className="text-left">
                Helps you in elevating your spiritual journey
              </div>
            </div>
            <a href="https://lsm.lvl.fit/?data=guya8119qpa1psiuzwii">
              <button
                className={cn(
                  `bg-gradient-to-r from-[#44199A] via-[#9D77EE]  to-[#9D77EE] text-[#white] text-lg  font-extrabold 
                hover:from-[#9D77EE] hover:via-[#9D77EE]  hover:to-[#44199A] 
                transition-all duration-500
                p-3 hover:p-4 px-5 hover:px-6 my-3  rounded-3xl 
                w-60 h-14 transform hover:scale-105
              md:text-xl md:my-4  
            `,
                )}
              >
                Learn More
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutComp;
