/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useRef } from 'react';
import { StaticImageData } from 'next/image';
import { publications } from './pr-data';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import { useMobileResponsive } from '@/hooks';
import { cn } from '@/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

function Img(props: { img: StaticImageData; alt?: string }) {
  return (
    <>
      {/* eslint-disable-next-line */}
      <img
        src={props.img.src}
        alt={props.alt || `image`}
        className={[`block `, `max-h-[100%]`, ``].join(` `)}
      />
    </>
  );
}

function PrLinks({ splideRef }: any) {
  const { windowWidth } = useMobileResponsive();

  const handleNextSlide = () => {
    if (splideRef.current && splideRef.current.splide) {
      splideRef.current.splide.go(1);
    }
  };

  const seenUrls = new Set();

  return (
    <div className="text-white rounded-3xl w-fit m-8 bg-[#282234] border border-[#362E48] md:mx-20">
      <>
        <div className="block py-3">
          {/* Added 'items-center' */}
          <div
            className={[`grid`, `grid-cols-4`, `gap-[1em]`, `p-3 mx-3`].join(
              ` `,
            )}
          >
            {publications.map((pub) => {
              if (
                seenUrls.has(pub.pr_url) ||
                (pub.pr_url2 && seenUrls.has(pub.pr_url2))
              ) {
                return null; // Skip this item if either URL has been seen
              }

              // Add the URLs to the seen set
              seenUrls.add(pub.pr_url);
              if (pub.pr_url2) {
                seenUrls.add(pub.pr_url2);
              }

              return (
                <>
                  <div
                    key={pub.pr_url}
                    style={{
                      boxShadow: `inset 0px 4px 4px rgba(0, 0, 0, 0.25)`,
                    }}
                    onClick={() => window.open(pub.pr_url, `_blank`)}
                    className={cn(
                      `flex  items-center justify-center p-1   max-h-[4em] rounded-xl bg-white cursor-pointer`,
                      {},
                    )}
                  >
                    <Img img={pub.img} />
                  </div>
                  {pub.img2 && (
                    <div
                      key={pub.pr_url2}
                      style={{
                        boxShadow: `inset 0px 4px 4px rgba(0, 0, 0, 0.25)`,
                      }}
                      onClick={() => window.open(pub.pr_url2, `_blank`)}
                      className={cn(
                        `flex  items-center justify-center p-2   max-h-[4em] rounded-xl bg-white cursor-pointer`,
                        {},
                      )}
                    >
                      <Img img={pub.img2} />
                    </div>
                  )}
                </>
              );
            })}
          </div>
          {/* <div className="border border-white  bg-center justify-center w-[40%]"></div> */}
          <div className="flex justify-center  max-w-3xl  mx-auto p-4 ">
            <p className={cn(`text-left text-xl`)}>
              All the times we got featured in press
            </p>
            <div onClick={handleNextSlide} className="pl-5 flex items-center">
              <FontAwesomeIcon icon={faChevronRight} size={'2x'} />
              <FontAwesomeIcon
                icon={faChevronRight}
                size="1x"
                className="text-[#9D9D9D]"
              />
            </div>
          </div>
        </div>
      </>
      {/* )} */}
    </div>
  );
}

export function PrCard(props: { publication: typeof publications[0] }) {
  const { isMobile, windowWidth } = useMobileResponsive();

  if (!props.publication.feature_img) return <></>;

  return (
    <div className="flex mx-2 md:mx-20">
      <div className={cn(`rounded-3xl w-full md:w-1/2 m-8  bg-[#282234] `, {})}>
        <a
          href={props.publication.pr_url}
          className={[
            `bg-white`,
            `shadow-md`,
            `drop-shadow-lg`,
            `rounded-[0.75em]`,
            `text-[#141414]`,
          ].join(` `)}
        >
          {/* blog image */}

          <div className="p-2 relative">
            <div
              style={{
                backgroundSize: `cover`,
                backgroundImage: `url(${props.publication.feature_img.src})`,
                height: '200px', // Example height
                width: '100%', // Example width
              }}
              className={[`pb-[53%]`, `rounded-[0.75em] `].join(` `)}
            />
            <img
              src={props.publication.img.src}
              className="absolute bottom-2 left-2 w-20 bg-white p-1 rounded-bl-lg rounded-tr-lg"
              alt=""
            />
          </div>

          {/* blog title */}
          <div
            className={[
              `text-base xl:text-xl text-white`,
              `font-bold`,
              `px-4`,
              `py-3`,
              `pb-5`,
              `line-clamp-3`,
            ].join(` `)}
          >
            {props.publication.title}
          </div>

          <div className="pt-3 text-[#9D77EE] underline ml-auto text-right p-3">
            <>Read more ....</>
          </div>

          {/* blog description */}
        </a>
      </div>

      {windowWidth > 767 && (
        <div className="rounded-3xl w-1/2 m-8 bg-[#282234] relative ">
          <a
            href={props.publication.pr_url2}
            className={[
              `bg-[#282234]`,
              `shadow-md`,
              `drop-shadow-lg`,
              `rounded-3xl`,
              `text-[#141414]`,
              `relative`,
              `block`, // Ensure the <a> tag is a block element
              `h-full`, // Full height to position the child elements properly
            ].join(` `)}
          >
            {/* Blog Image */}
            <div className="p-2 relative">
              <div
                style={{
                  backgroundSize: `cover`,
                  backgroundImage: `url(${props.publication.feature_img2.src})`,
                  height: '200px', // Example height
                  width: '100%', // Example width
                }}
                className={[`pb-[53%]`, `rounded-[0.75em]`].join(` `)}
              />
              <img
                src={props.publication.img2.src}
                className="absolute bottom-2 left-2 w-20 bg-white p-1 rounded-bl-lg rounded-tr-lg"
                alt=""
              />
            </div>

            <div
              className={[
                `text-base xl:text-xl text-white`,
                `font-bold`,
                `px-4`,
                `py-3`,
                `pb-5`,
                `line-clamp-3`,
              ].join(` `)}
            >
              {props.publication.title2}
            </div>

            <div className="absolute  bottom-3 right-3 text-[#9D77EE] underline">
              Read more ....
            </div>
          </a>
        </div>
      )}
    </div>
  );
}

export function Pr() {
  const { windowWidth } = useMobileResponsive();

  const splideRef = useRef<any>(null);

  useEffect(() => {
    // Ensure Splide instance is available after component mounts
    if (splideRef.current) {
      splideRef.current.splides;
    }
  }, []);

  return (
    <div className={[`mx-auto  bg-level-purple`].join(` `)}>
      <h2
        className={[
          `px-2 pt-0 md:pt-9 mt-40 md:mt-20 `,
          `font-black`,
          `text-3xl`,
          `md:text-5xl`,
          `text-[white]`,
          `text-center`,
        ].join(` `)}
      >
        Press Releases
      </h2>
      {/* eslint-disable-next-line */}
      {/* @ts-ignore */}
      <Splide
        // hasTrack={true}
        ref={splideRef}
        options={{
          type: `loop`,
          // padding: '10px',
          easing: `cubic-bezier(0, 0.55, 0.45, 1)`,
          drag: true,
          // autoplay: true,
          lazyLoad: `nearby`,
          perPage: windowWidth < 1500 ? 1 : 2,
          focus: `center`,
          trimSpace: false,
          pagination: false,
          flickMaxPages: 1,
          rewind: true,
          // arrows: false,
          classes: {
            arrows: 'custom-splide-arrows-pr-section',
            arrow: 'splide__arrow', // Ensure this matches the CSS class
            prev: 'splide__arrow--prev', // Ensure this matches the CSS class
            next: 'splide__arrow--next', // Ensure this matches the CSS class
            focus: 'focus-css-splide',
          },
        }}
      >
        {[
          <PrLinks key={0} splideRef={splideRef} />,
          ...publications.map((pub, index) =>
            pub.feature_img ? (
              <PrCard key={index + 1} publication={pub} />
            ) : null,
          ),
        ]
          .filter((el) => !!el)
          .map((elem, i) => (
            <SplideSlide
              key={i + 1}
              className={[`flex`, `items-stretched`, `justify-center`].join(
                ` `,
              )}
            >
              <div className="w-full">{elem}</div>
            </SplideSlide>
          ))}
      </Splide>
    </div>
  );
}
