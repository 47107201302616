import React from 'react';
import { Nav } from '@/components/nav';
import { Hero } from '@/components/hero';
import { Meta } from '@/components/meta';
import { Pr } from '@/components/pr';
import { useWindowDimension } from '@/hooks';
import { KeyFeatures } from '@/components/key-features';
import { ContactForm } from '@/components/contact-form';
import { DownloadApp } from '@/components/download-app';
import { Testimonials } from '@/components/testimonials';
import { PricingPlans } from '@/components/pricing-plan';
import { CircleShapeBox } from '@/components/circle-shape';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BrandCollabs } from '@/components/brand-collabs';
import AboutComp from '@/components/about-comp';
import Awards from '@/components/awards';
import { socialProfiles } from '@/config/socialProfile';
import Blackfriday from '@/components/Blackfridaybar';

export default function Home() {
  const winDim = useWindowDimension();

  const innerWidth = winDim?.innerWidth || 900;
  const [unsubFormResp, setUnsubFormResp] = React.useState({
    unsubscribed: false,
    message: ``,
  });

  React.useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    setUnsubFormResp(() => ({
      unsubscribed: !!search.get(`unsubscribed`),
      message: search.get(`message`) || ``,
    }));
  }, []);

  return (
    <>
      <Nav />

      {/* mail unsubscribe confirmation */}
      {unsubFormResp.unsubscribed && unsubFormResp.message && (
        <div className={[`max-w-7xl`, `mx-auto`, `my-6`, `px-2`].join(` `)}>
          <div
            className={[
              `text-[#141414]`,
              `border-4`,
              `border-[#7356E850]`,
              `rounded-2xl`,
              `p-4`,
              `text-2xl`,
              `font-thin`,
              `text-center`,
            ].join(` `)}
          >
            {unsubFormResp.message}
          </div>
        </div>
      )}

      <Hero />
      <Meta />

      <AboutComp />
      <Awards />

      <div className="z-[30]">
        <KeyFeatures />
      </div>

      <BrandCollabs />

      <div className="relative overflow-hidden">
        <Pr />
      </div>

      <div className="relative overflow-hidden">
        <PricingPlans />
        {innerWidth > 900 ? (
          <CircleShapeBox className={[`absolute`, `inset-0`].join(` `)} />
        ) : null}
      </div>

      <Testimonials />

      <ContactForm />

      <div
        className={[`bg-level-purple`, `py-4 md:py-20`, `px-4`, `my-20`].join(
          ` `,
        )}
      >
        <h2
          className={[
            `px-2`,
            `font-black`,
            `md:text-4xl text-3xl`,
            `text-[white]`,
            `text-center`,
          ].join(` `)}
        >
          Join A Community Of SuperMinds
        </h2>

        <div
          className={[
            `mt-10`,
            `mx-auto`,
            `max-w-5xl`,
            `flex`,
            `gap-x-16`,
            `gap-y-14`,
            `items-center`,
            `justify-center`,
            `flex-wrap`,
          ].join(` `)}
        >
          {socialProfiles.map((profile) => (
            <a
              key={profile.name}
              href={profile.permalink}
              className="text-[white]"
              aria-label="Level Social Media"
            >
              <FontAwesomeIcon
                icon={profile.faIcon}
                fontSize="4.4em"
                aria-label="social icon"
              />
            </a>
          ))}
        </div>
      </div>
    </>
  );
}
