import {
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube,
  faTelegram,
  faDiscord,
} from '@fortawesome/free-brands-svg-icons';

export const socialProfiles = [
  {
    name: `Instagram`,
    faIcon: faInstagram,
    permalink: `https://instagram.com/levelsupermind`,
  },
  {
    name: `LinkedIn`,
    faIcon: faLinkedinIn,
    permalink: `https://www.linkedin.com/company/levelsupermind`,
  },
  {
    name: `Twitter`,
    faIcon: faTwitter,
    permalink: `https://twitter.com/levelsupermind`,
  },
  {
    name: `YouTube`,
    faIcon: faYoutube,
    permalink: `https://www.youtube.com/@LevelSuperMind.`,
  },
  {
    name: `Telegram`,
    faIcon: faTelegram,
    permalink: `https://t.me/leveldotgame`,
  },
  // {
  //   name: `Discord`,
  //   faIcon: faDiscord,
  //   permalink: `https://discord.com/invite/TJvSuQQr2d`,
  // },
];
