import Image from 'next/image';
import React from 'react';
import firstImg from '../../assets/new-assets/home/google.webp';
import secondImg from '../../assets/new-assets/home/2.webp';
import thirdImg from '../../assets/new-assets/home/4.webp';
import fourthImg from '../../assets/new-assets/home/3.webp';
import { useMobileResponsive } from '@/hooks';
import { Splide, SplideSlide } from '@splidejs/react-splide';

const Awards = () => {
  const { isMobile } = useMobileResponsive();
  return (
    <>
      <div className="block text-center space-y-12 md:space-y-0 md:flex justify-around items-center p-9 bg-[#282234] px-9 md:px-24 lg:px-32 xl:px-48 ">
        <div>
          <Image src={secondImg} />
        </div>
        <div className="">
          <Image src={firstImg} />
        </div>
        <div>
          <Image src={thirdImg} />
        </div>
        <div>
          <Image src={fourthImg} />
        </div>
      </div>
    </>
  );
};

export default Awards;
