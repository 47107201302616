import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

type PropTypes = {
  rating: number;
  className?: string;
  totalRatingCount: number;
};

export function StarRating(props: PropTypes) {
  return (
    <div className={[props.className, `inline-flex`, `flex-row`].join(` `)}>
      {Array(props.totalRatingCount)
        .fill(`*`)
        .map((_, i) => (
          <FontAwesomeIcon
            key={i}
            icon={faStar}
            width="1em"
            height="1em"
            className={[
              `mx-1`,
              i < props.rating ? `text-[#F9CE47]` : `text-gray-200`,
            ].join(` `)}
          />
        ))}
    </div>
  );
}
