'use client';
import React from 'react';
import AudioComp from './AudioComp';
import Scallop from '../../assets/new-assets/home/features/workoutscallop.svg';
// import bg from '../../assets/new-assets/home/features/yellowBg.webp';
import bg from '../../assets/new-assets/home/features/yellowBg (1).webp';
// import triangle from '../../assets/new-assets/home/features/yellowTri.webp';
// import mediation from '../../assets/new-assets/home/Meditation.webp';
// import phone1 from '../../assets/new-assets/home/features/yellowPhone1.webp';
// import phone2 from '../../assets/new-assets/home/features/yellowPhone2.webp';
import { cn } from '@/utils';
import { useMobileResponsive } from '@/hooks';
import Image from 'next/image';
import ConsistentHeightWrapper from './ConsistentHeightWrapper';

const WorkoutComp = () => {
  const { windowWidth } = useMobileResponsive();

  return (
    <ConsistentHeightWrapper className="relative block md:flex justify-around items-center bg-[#f7d766] w-full p-8 mt-32">
      <div
        className={cn(`absolute  left-0 -top-8  min-w-full`, {
          '-top-4': windowWidth < 560,
          '-top-14': windowWidth > 1120,
          '-top-20': windowWidth > 1800,
        })}
      >
        <Scallop className="" />
      </div>

      <div className="text-level-purple mt-10 ml-0 md:ml-10 md:w-[35%] ">
        <div className="space-y-3">
          <strong className="text-2xl md:text-5xl font-extrabold ">
            Workouts, Yoga
            {/* and Step tracker */}
          </strong>
          <p className="text-xl font-semibold">
            Move your body with HIIT, Strength training or everyone’s favorite
            Yoga.
            <br />
            <br />
          </p>
        </div>

        <div className="pt-8 space-y-3">
          <span className="text-lg font-semibold text-[#0F0622]">
            Workouts for you
          </span>

          <AudioComp compName="workout" />
        </div>
      </div>
      <div className="relative pt-10 md:w-[35%]">
        <Image src={bg} alt="" priority />
        {/* <div className="absolute top-32 transition-all duration-300 hover:-translate-x-10">
          <img src={phone1.src} alt="" className="phone" />
        </div>
        <div className="absolute top-12 right-0 transition-all duration-300 hover:translate-x-10">
          <img src={phone2.src} alt="" className="phone" />
        </div> */}
      </div>
    </ConsistentHeightWrapper>
  );
};

export default WorkoutComp;

// 'use client';
// import React from 'react';
// import AudioComp from './AudioComp';
// import Scallop from '../../assets/new-assets/home/features/workoutscallop.svg';
// import bg from '../../assets/new-assets/home/features/yellowBg (1).webp';
// import { cn } from '@/utils';
// import { useMobileResponsive } from '@/hooks';
// import Image from 'next/image';
// import ConsistentHeightWrapper from './ConsistentHeightWrapper';

// const WorkoutComp = () => {
//   const { windowWidth } = useMobileResponsive();

//   return (
//     <ConsistentHeightWrapper className="relative block md:flex justify-around items-center bg-[#f7d766] w-full px-8 py-0 mt-0 lg:mt-10 xl:mt-20">
//       <div
//         className={cn(`absolute  left-0 -top-8  min-w-full`, {
//           '-top-4': windowWidth < 560,
//           '-top-14': windowWidth > 1120,
//           '-top-20': windowWidth > 1800,
//         })}
//       >
//         <Scallop className="" />
//       </div>

//       <div className="text-level-purple mt-10 ml-0 md:ml-10 md:w-[35%] ">
//         <div className="space-y-3">
//           <strong className="text-3xl md:text-5xl font-extrabold ">
//             Workouts, Yoga
//             {/* and Step tracker */}
//           </strong>
//           <p className="text-xl font-semibold">
//             Move your body with HIIT, Strength training or everyone’s favorite
//             Yoga.
//             <br />
//             <br />
//           </p>
//         </div>

//         <div className="pt-8 space-y-3">
//           <span className="text-lg font-semibold text-[#0F0622]">
//             Workouts for you
//           </span>

//           <AudioComp compName="workout" />
//         </div>
//       </div>
//       <div className="relative pt-10 md:w-[35%]">
//         <Image src={bg} alt="" priority />
//         {/* <div className="absolute top-32 transition-all duration-300 hover:-translate-x-10">
//           <img src={phone1.src} alt="" className="phone" />
//         </div>
//         <div className="absolute top-12 right-0 transition-all duration-300 hover:translate-x-10">
//           <img src={phone2.src} alt="" className="phone" />
//         </div> */}
//       </div>
//     </ConsistentHeightWrapper>
//   );
// };

// export default WorkoutComp;
