import React from 'react';
import { LoaderIcon } from 'react-hot-toast';

export const LazLoader = () => {
  return (
    <div className="w-10 h-10 flex justify-center items-center text-[#7356e8]">
      <LoaderIcon />
    </div>
  );
};
