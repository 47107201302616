import IbtimesImg from './assets/ibtimes.webp';
// import TimesOfIndia from './assets/timesofindia.jpeg';
import OutlookImg from './assets/outlook.webp';
import IndiatvImg from './assets/indiatv.webp';
import NewsableImg from './assets/newsable.webp';
import NewstrackImg from './assets/newstrack.webp';
import BrandequityImg from './assets/brandequity.webp';
import StudentIndiaTVImg from './assets/india-tv__students.webp';
import LokmattimesHindiImg from './assets/lokmattimes-hindi.webp';
import HarshilRanveerAayushImg from './assets/harshil-aayush-ranveer.webp';
import ManishaImg from './assets/manisha.jpg';
import allThree from '../../assets/new-assets/pr/ahr.webp';
import abstractBG from './assets/abstract-bg.webp';

import TimesOfIndia from '../../assets/new-assets/pr/timesofindia.webp';
import moneyControl from '../../assets/new-assets/pr/moneycontrol.webp';
import moneyControlMain from '../../assets/new-assets/pr/moneycontrolmain.webp';
import moneyControlNew from '../../assets/new-assets/pr/moneyControlNew.webp';

import adGully from '../../assets/new-assets/pr/adgully.webp';
import adGullyMain from '../../assets/new-assets/pr/adgullymain.webp';
import IndiatvMain from '../../assets/new-assets/pr/indiaMain.webp';
import mediaBreif from '../../assets/new-assets/pr/mediaBreif.webp';
import mediaBreifMain from '../../assets/new-assets/pr/mediaBreifMain.webp';
import newsNine from '../../assets/new-assets/pr/newsNine.webp';
import newsNineMain from '../../assets/new-assets/pr/newsNineMain.webp';

import newsAbleMain from '../../assets/new-assets/pr/allMeditation.webp';
import passionateInMarketing from '../../assets/new-assets/pr/passionInMarketing.webp';
import LokmattimesImg from './assets/lokmattimes.webp';
import LokmatMain from '../../assets/new-assets/pr/lokmatMain.webp';

export const publications = [
  {
    img: NewstrackImg,
    name: `News Track Live`,
    date: `2022/09/25`,
    title: `Find Your Way With Level Supermind - An Indian App That Boosts Your Mind Performance`,
    pr_url: `https://english.newstracklive.com/news/find-your-way-with-level-supermind--an-indian-app-that-boosts-your-mind-performance-sc71-nu343-1251356-1.html`,
    feature_img: null,
    textBgColor: `#180859`,
    imgBgColor: `#180859`,
  },

  {
    img: LokmattimesImg,
    pr_url: `https://www.lokmattimes.com/lifestyle/you-dont-have-to-choose-between-science-and-spirituality-for-mental-enhancement-with-level-supermind-a475/`,
  },
  {
    img: NewsableImg,
    pr_url: `https://newsable.asianetnews.com/lifestyle/gen-z-picks-up-a-new-diwali-ritual-with-level-supermind-app-snt-rkd1jv`,
  },

  {
    img: LokmattimesHindiImg,
    name: `Lokmat Hindi`,
    pr_url: `https://www.google.com/amp/s/www.lokmatnews.in/technology/guided-meditation-increases-memory-power-easy-level-with-supermind-app-here-are-its-amazing-benefits-b408/amp/`,
    date: `2022/10/31`,
    title: `गाइडेड मेडिटेशन से मेमरी पावर बढ़ाना हुआ आसान लेवल सूपरमाइंड ऐप के साथ, ये हैं इसके जबर्दस्त फायदे`,
    feature_img: null,
    textBgColor: `#180859`,
    imgBgColor: `#180859`,
  },
  {
    img: IbtimesImg,
    bgIMG: abstractBG,
    name: `International Business Times`,
    pr_url: `https://www.ibtimes.sg/bring-power-meditation-wherever-you-go-level-supermind-67037`,
    date: `2022/09/28`,
    title: `Bring The Power of Meditation Wherever You Go, With Level Supermind`,
    feature_img: ManishaImg,
    // feature_img: `./assets/manisha.jpg`,
    description: `Whenever it comes to words like Yoga, and Meditation, the millennials, and Gen-Z think of them as something done on vacations and retreats.`,
    // bgColor: `#A6E6FF`,
    iconColor: `#A6E6FF`,
    bgColor: `transparent`,
    textColor: `#fff`,
    imgBgColorMobile: `#a6e6ff`,

    imgBgColor: `linear-gradient( #a6e6ff, rgba(11, 128, 183, 0.41))`,
    textBgColor: `linear-gradient(#1eaef1, rgba(11, 128, 183, 0.41))`,

    img2: BrandequityImg,
    bgIMG2: abstractBG,
    name2: `Brand Equity: Economic Times`,
    pr_url2: `https://brandequity.economictimes.indiatimes.com/news/digital/ranveer-allahbadia-harshil-karia-collab-to-create-a-nike-for-the-mind/96692014`,
    date2: `2022/01/03`,
    title2: `Ranveer Allahbadia, Harshil Karia collab to create a ‘Nike for the mind’`,
    feature_img2: HarshilRanveerAayushImg,
    description2: `The purpose of this new venture is to create a business that supercharges the mind.`,
    // bgColor: `#7356e8`,
    iconColor2: `#7356e8`,
    bgColor2: `transparent`,
    textColor2: `#fff`,
    textBgColor2: `linear-gradient( rgba(57, 19, 214, 0.42),#190956)`,
    imgBgColor2: `linear-gradient( rgba(57, 19, 214, 0.42), #7356e8)`,
    imgBgColorMobile2: `#7356e8`,
  },

  {
    img: TimesOfIndia,
    bgIMG: abstractBG,
    name: `The Times Of India`,
    pr_url: `https://timesofindia.indiatimes.com/business/level-supermind-googles-best-app-on-play-in-2023/articleshow/107799113.cms`,
    date: `2024/02/18`,
    title: `Google rated this app as best on Play in 2023`,
    feature_img: allThree,
    // feature_img2: `../../assets/new-assets/pr/sds.webp`,
    description: `Harshil Karia, Ranveer Allahbadia and Ayush Anand's Level Supermind app was ranked by Google as the best app on Google Play in India in 2023 on the basis of popularity and ratings`,
    // bgColor: `#A6E6FF`,
    iconColor: `#A6E6FF`,
    bgColor: `transparent`,
    textColor: `#fff`,
    imgBgColorMobile: `#a6e6ff`,

    imgBgColor: `linear-gradient( #a6e6ff, rgba(11, 128, 183, 0.41))`,
    textBgColor: `linear-gradient(#1eaef1, rgba(11, 128, 183, 0.41))`,

    img2: IndiatvImg,
    bgIMG2: abstractBG,
    name2: `India TV`,
    pr_url2: `https://www.google.com/amp/s/www.indiatvnews.com/amp/health/are-you-feeling-exam-anxiety-beat-the-stress-with-guided-meditation-to-increase-focus-2022-12-16-831684`,
    date2: `2022/12/16`,
    title2: `Are you feeling exam anxiety? Beat the stress with guided meditation`,
    feature_img2: StudentIndiaTVImg,
    description2: `Meditation is one of the best ways for students to increase their productivity and study calmly under the pressure.`,
    // bgColor: `#f9ce47`,
    iconColor2: `#f9ce47`,
    bgColor2: `transparent`,
    textColor2: `#000`,
    imgBgColorMobile2: `#f9ce47`,
    imgBgColor2: `linear-gradient( #f9ce47, rgba(164, 104, 16, 0.7))`,
    textBgColor2: `linear-gradient(#f6b434, rgba(164, 104, 16, 0.7))`,
  },

  {
    img: moneyControl,
    name: `Money Control`,
    pr_url: `https://www.moneycontrol.com/news/technology/google-plays-best-apps-games-of-2023-in-india-level-supermind-battle-stars-others-11830961.html`,
    title: `Google Play's best apps, games of 2023 in India: Level SuperMind, Battle Stars, others`,
    feature_img: moneyControlNew,

    img2: adGully,
    name2: `AdGully`,
    pr_url2: `https://www.adgully.com/level-supermind-is-building-a-nike-for-the-mind-harshil-karia-126995.html`,
    title2: `Level Supermind is building a Nike for the mind: Harshil Karia`,
    feature_img2: adGullyMain,
  },
  {
    img: IndiatvImg,
    name: `India Tv`,
    pr_url: `https://www.indiatvnews.com/technology/news/top-picks-of-2023-google-play-store-best-apps-and-games-revealed-2023-11-30-905065`,
    title: `Google unveils top apps and games of 2023: Check complete list here`,
    feature_img: IndiatvMain,

    img2: NewsableImg,
    name2: `Asianet newsable`,
    pr_url2: `https://newsable.asianetnews.com/lifestyle/gen-z-picks-up-a-new-diwali-ritual-with-level-supermind-app-snt-rkd1jv`,
    title2: `Gen Z picks up a New Diwali Ritual with Level SuperMind App`,
    feature_img2: newsAbleMain,
  },

  {
    img: passionateInMarketing,
    name: `Passionate in Marketing`,
    pr_url: `https://www.passionateinmarketing.com/made-in-india-meditation-and-mind-performance-app-level-supermind-wins-app-of-the-year-at-google-plays-best-of-2023-awards/`,
    title: `Made in India Meditation and Mind Performance App, Level Supermind Wins ‘App of the Year’ at Google Play’s Best of 2023 Awards`,
    feature_img: moneyControlMain,

    img2: LokmattimesImg,
    name2: `Lokmat times`,
    pr_url2: `https://www.lokmattimes.com/lifestyle/you-dont-have-to-choose-between-science-and-spirituality-for-mental-enhancement-with-level-supermind-a475/`,
    title2: `You Don’t Have To Choose Between Science and Spirituality For Mental Enhancement, With Level Supermind You Get The Best of Both
    `,
    feature_img2: LokmatMain,
  },

  {
    img: mediaBreif,
    name: `Media Breif`,
    pr_url: `https://mediabrief.com/level-supermind-named-app-of-the-year-at-google-plays-best-of-2023-awards/`,
    title: `Level Supermind named ‘App of the Year’ at Google Play’s Best of 2023 Awards`,
    feature_img: mediaBreifMain,

    img2: newsNine,
    name2: `News Nine`,
    pr_url2: `https://www.news9live.com/technology/google-play-best-of-2023-monopoly-go-level-supermind-focus-calm-crowned-best-game-best-app-2364323`,
    title2: `Google Play Best of 2023:Monopoly Go! and Level SuperMind crowned Best Game and Best App
    `,
    feature_img2: newsNineMain,
  },
];
